<template>
  <div class="d-flex flex-column flex-column-fluid my-10">
    <!--begin::Content header-->
    <h1 class="font-weight-bolder text-dark font-size-h1 text-center">
      {{ $t("LOGIN.WELCOME") }}
    </h1>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="text-center">
      <a href="#" class="justify-content-center mt-5 pb-lg-0 pb-10">
        <img class="img-fluid" v-bind:src="backgroundImage" />
      </a>
    </div>

    <div class="text-center">
      <div class="text-muted font-weight-bold font-size-h4">
        {{ $t("LOGIN.NEW") }}
        <router-link
          class="text-info font-weight-bolder"
          :to="{ name: 'register' }"
        >
          {{ $t("LOGIN.REGISTER") }}
        </router-link>
      </div>
    </div>

    <b-form class="form flex-center" @submit.stop.prevent="onSubmit">
      <b-alert class="my-5" variant="danger" :show="errors.length">
        {{ errors }}
      </b-alert>

      <b-form-group
        id="example-input-group-1"
        label=""
        label-for="example-input-1"
      >
        <label class="font-size-h6 font-weight-bolder text-dark">{{
          $t("LOGIN.USERNAME")
        }}</label>

        <b-form-input
          size="lg"
          class="form-control form-control-solid h-auto py-5 px-6"
          id="example-input-1"
          name="example-input-1"
          v-model="$v.form.user_id.$model"
          :state="validateState('user_id')"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.user_id.email">
          Must use email format: example@example.com
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.user_id.required">
          {{ $t("LOGIN.REQUIRED") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="example-input-group-2"
        label=""
        label-for="example-input-2"
      >
        <label class="font-size-h6 font-weight-bolder text-dark">{{
          $t("LOGIN.PASSWORD")
        }}</label>

        <b-form-input
          size="lg"
          class="form-control form-control-solid h-auto py-5 px-6"
          type="password"
          id="example-input-2"
          name="example-input-2"
          v-model="$v.form.password.$model"
          :state="validateState('password')"
          aria-describedby="input-2-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.password.required">
          {{ $t("LOGIN.REQUIRED2") }}
        </b-form-invalid-feedback>

        <b-form-invalid-feedback v-if="!$v.form.password.minLength">
          Password must be at least 6 characters
        </b-form-invalid-feedback>
      </b-form-group>

      <!--begin::Action-->
      <div
        class="
          form-group
          d-flex
          flex-wrap
          justify-content-between
          align-items-center
        "
      >
        <router-link
          class="text-muted my-3 mr-2 font-size-h6 font-weight-bold"
          :to="{ name: 'forgotPassword' }"
          >{{ $t("LOGIN.FORGOT") }}</router-link
        >
      </div>

      <div class="d-flex flex-column">
        <button
          ref="kt_login_signin_submit"
          class="
            btn btn-primary
            text-white
            font-weight-bolder
            px-9
            py-4
            my-3
            font-size-h6
          "
        >
          {{ $t("LOGIN.LOGIN") }}
        </button>
      </div>
      <!--end::Action-->
    </b-form>

    <!--begin::Form-->
    <div class="d-flex flex-row-fluid flex-center">
      <div class="login-form login-signin"></div>
    </div>
    <!--end::Form-->

    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { LOGIN, RESET_ERROR } from "@/modules/auth/store/auth";

import { validationMixin } from "vuelidate";
import { minLength, required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      form: {
        user_id: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      user_id: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  beforeMount() {
    this.$store.dispatch(RESET_ERROR);
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        user_id: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.user_id.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(RESET_ERROR);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch((error) => {
          console.log("Login Failed");
          this.$bvModal.msgBoxOk(error.response.data.message, {
            title: "Login Failed",
            bodyClass: "h4",
            okVariant: "danger",
            centered: true,
            footerBgVariant: "light",
          });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
  computed: {
    errors() {
      return this.$store.state.Auth.errors;
    },
    backgroundImage() {
      return process.env.BASE_URL + "media/logos/tms.jpg";
    },
  },
};
</script>
